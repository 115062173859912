
.pagination {

  display: flex !important;
}

.filterButtonContainer {
  margin-left: 1rem;
}

.filterButtonContainer .on {
  ;
}

.filterButtonContainer .empty{
  border-color: darkgray !important;
  color: darkgray !important;
}