.container {

  //min-width: 400px;
  min-height: 175px;
  max-height: 190px;
  position: relative;
}

.tall {
  max-height: 675px !important;
}

.gridItem {

}

.infoIcon {
  position: absolute;
  top: 0.0em;
  right: 0.0em;
}

.wide {
  @extend .container;
  width: 100%;
  min-height: 135px !important;
  height: 135px !important;
  //max-height: 100px;
  padding: 0.75em !important;
  //background-color: rebeccapurple !important;
}

.headerContainer {
  margin-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.surface {
  margin: 2%;
  width: 96%;
  height: 96%;
}

.card.horizontal {
  height: 100%;
}

.card.vertical {

  //background: beige;
}

.card {
  display: flex;
  flex-direction: column;
  //height: 160px;
  justify-content: space-between;
  padding: 0.5em;
}

.cardMain {
  display: flex;
  flex-direction: row;

  margin-left: 5px;
  margin-top: 5px;

}

.iconContainer {
  height: auto;
  width: auto;
  //background-color: black;
  display: flex;
  flex-direction: column;
}

.cardMedia {
  //background-color: black;
}

.cardContent {
  padding: 0 !important;
  margin-left: 1em;
  position: relative;

  flex-grow: 1;
}

//.detailsTable {
//  font-size: 8px;
//}

.detailsTable tr > td:nth-child(1) {
  width: 70px;
  font-size: 12px;
}

.detailsTable tr > td:nth-child(2) {
  position: relative;
  width: 160px !important;
}

.detailsTable {
  width: 100%;
  height: 100%;
  max-width: 385px;
}

.detailsTable tr > td {
  white-space: nowrap;
}

.header {
  white-space: nowrap !important;
  margin-bottom: 0.25em;
}

.civsList {
  display: flex;
  flex-wrap: nowrap;
  //justify-content: space-between;
  //background-color: rebeccapurple;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}


.civsListContainer > .civsList:first-child {
  //justify-content: flex-end;
  justify-content: space-between;

}

.civsListContainer > .civsList:last-child {
  //justify-content: flex-start;
  justify-content: space-between;


}
.civsList > * {
  margin-left: 3px !important;
  margin-right: 3px !important;
}
//.civsList > *:not(:first-child) {
//
//  margin-left: 0.25rem;
//}

.civsListContainer {
  margin-top: 0.5em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.civsListContainerLabelContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;

  margin-right: 0.45rem;
  margin-left: 0.45rem;
}

.civsListContainerLabelContainer > div {

}


.civsListContainerLabelContainer > div:nth-child(2) {
  margin-left: 0.35em;
  display: flex;
  flex-direction: column;
  margin-right: 0.35em;
  align-items: center;

}

.clickable {

  cursor: pointer;
}

.clickable:hover .card {
  background-color: rgba(255, 255, 255, 0.17);
}

.clickable:hover .card:global(.IsarX) {
  background-color: rgba(255, 255, 255, 0.12);
}

.civListTable {
}

.civListTable .tooltip {
  cursor: help;
  display: flex;
  line-height: 12px;
  font-size: 14px;
  flex-direction: column;
}

.civListTable .tooltip * {
  text-align: center;
}